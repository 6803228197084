import {
  createNativeStackNavigator,
  NativeStackScreenProps,
} from '@react-navigation/native-stack';
import React, { useContext } from 'react';
import { GlobalLabels } from '../constants';
import { AuthContext } from '../context/AuthContextProvider';
import { UserRole } from '../shared/types/users';
import ContactsScreen from '../views/AuthenticatedStack/ContactsScreen';
import DistributorScreen from '../views/AuthenticatedStack/DistributorScreen';
import FavouritesScreen from '../views/AuthenticatedStack/FavouritesScreen';
import GlobalInvoicesScreen from '../views/AuthenticatedStack/GlobalInvoicesScreen';
import HomeScreen from '../views/AuthenticatedStack/HomeScreen';
import MyAccountScreen from '../views/AuthenticatedStack/MyAccountScreen';
import ProjectsOverviewScreen from '../views/AuthenticatedStack/ProjectsOverviewScreen';
import RebatesScreen from '../views/AuthenticatedStack/Rebates/RebatesScreen';
import ResourcesScreen from '../views/AuthenticatedStack/ResourcesScreen';
import TrainingScreen from '../views/AuthenticatedStack/TrainingScreen';
import CompanyAdminStackNavigator from './CompanyAdminStackNavigator';
import GlobalAdminStackNavigator from './GlobalAdminStackNavigator';
import NewsStackNavigator from './NewsStackNavigator';
import ProjectStackNavigator from './ProjectStackNavigator';
import { AuthenticatedStackNavigatorParamList } from './types';
import RecyclingCalScreen from '../views/AuthenticatedStack/RecyclingCalScreen';

const Stack =
  createNativeStackNavigator<AuthenticatedStackNavigatorParamList>();

type AuthenticatedStackNavigatorProps =
  NativeStackScreenProps<AuthenticatedStackNavigatorParamList> & {
    //
  };

const AuthenticatedStackNavigator: React.FC<
  AuthenticatedStackNavigatorProps
> = ({ navigation, route }) => {
  const { user, activeCompany } = useContext(AuthContext);

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={
        !activeCompany && user?.role === UserRole.Admin
          ? 'globalAdminStack'
          : undefined
      }
    >
      <Stack.Screen
        name='home'
        // component={HomeScreen}
        options={{ title: 'Home' }}
      >{() => <HomeScreen navigation={navigation} route={route} />}</Stack.Screen>
      <Stack.Screen
        name='projectsOverview'
        component={ProjectsOverviewScreen}
        options={{ title: 'Projects Overview' }}
      />
      <Stack.Screen
        name='distributors'
        component={DistributorScreen}
        options={{ title: GlobalLabels.FindContact }}
      />
      <Stack.Screen
        name='resources'
        component={ResourcesScreen}
        options={{ title: 'Resources' }}
      />
      <Stack.Screen
        name='recyclingCal'
        component={RecyclingCalScreen}
        options={{ title: 'Recycling Calculator' }}
      />
      <Stack.Screen
        name='training'
        component={TrainingScreen}
        options={{ title: 'Training' }}
      />
      <Stack.Screen name='newsStack' component={NewsStackNavigator} />
      <Stack.Screen
        name='contacts'
        component={ContactsScreen}
        options={{ title: GlobalLabels.ZentiaContacts }}
      />
      <Stack.Screen name='projectStack' component={ProjectStackNavigator} />
      <Stack.Screen
        name='myAccount'
        component={MyAccountScreen}
        options={{ title: 'My Account' }}
      />
      <Stack.Screen
        name='rebates'
        //component={RebatesScreen}
        options={{ title: 'Rebates' }}
      >
        {() => <RebatesScreen navigation={navigation} route={route} />}
      </Stack.Screen>
      <Stack.Screen
        name='invoices'
        component={GlobalInvoicesScreen}
        options={{ title: 'Invoices' }}
      />
      <Stack.Screen
        name='favourites'
        component={FavouritesScreen}
        options={{ title: 'My Favourites' }}
      />

      {user?.role === UserRole.Admin && (
        <Stack.Screen
          name='globalAdminStack'
          component={GlobalAdminStackNavigator}
        />
      )}

      <Stack.Screen
        name='companyAdminStack'
        component={CompanyAdminStackNavigator}
      />
    </Stack.Navigator>
  );
};

export default AuthenticatedStackNavigator;
