
export enum GlobalSettingsIds {
    mailingList = "notificationMailingList",
    rebateTemplate = 'rebateTemplate',
    rebateMailingList = 'rebateMailingList',
    recalTemplate = 'recalTemplate'
}

export interface GlobalSettingsResponse{
    id: string;
    name: string;
    value: string;
    type: string;
    desc: string;
}

export interface CreateGlobalSettingsPayload{
    name: string;
    value: string;
    type: string;
    desc: string;
}

export interface UpdateGlobalSettingsPayload{
    name?: string;
    value?: string;
    type?: string;
    desc?: string;
}