import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { IPaginationMeta, Pagination } from 'nestjs-typeorm-paginate';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CgSpinner } from 'react-icons/cg';
import { FaFilter } from 'react-icons/fa';
import { RebateClient } from '../../../api/RebateClient';
import Button from '../../../components/shared/Button';
import H1 from '../../../components/shared/H1';
import P from '../../../components/shared/P';
import tw from '../../../config/tailwind';
import { AuthContext } from '../../../context/AuthContextProvider';
import DashboardLayout from '../../../layout/DashboardLayout';
import { CompanyResponse } from '../../../shared/types/companies';
import { Rebate, RebateStatus } from '../../../shared/types/rebates';
import { UserResponse, UserRole } from '../../../shared/types/users';
import { HomeScreenProps } from '../HomeScreen';
import RebateDetailsModal from './RebateDetailsModal';
import RebateFilterModal from './RebateFilterModal';
import { addIndexSuffix, displayQuarter, getFullQuarters } from './RebateFunction';
import RebateNewModal from './RebateNewModal';
import RebatePaginationControl from './RebatePaginationControl';
import { Animated, Easing } from 'react-native';

dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

export interface RebateResultFilter {
  company?: CompanyResponse | null;
  reviewer?: UserResponse | null;
  applicant?: UserResponse | null;
  status?: RebateStatus | null;
  quarters: { label: string; value: Date }[];
}

export const defaultFilter: RebateResultFilter = {
  company: null,
  reviewer: null,
  applicant: null,
  status: null,
  quarters: [],
};

function FilterTag({children}: PropsWithChildren<{}>){
  return <small
  style={tw`w-fit p-1 bg-green mr-2`}
>{children}</small>
}

const RebatesScreen: React.FC<HomeScreenProps> = ({ navigation, route }) => {
  const { getState } = navigation;
  const state = getState();

  const id: string | undefined =
    (state.routes[0]?.state?.routes[0]?.params as { id?: string })?.id ??
    undefined;

  const { user, activeCompany } = useContext(AuthContext);
  const isPartners = useMemo(() => activeCompany || user?.role !== UserRole.Admin, [activeCompany, user]);

  const [showNewModal, setShowNewModal] = useState<boolean | Date>(false);
  const [rebates, setRebates] = useState<Rebate[]>([]);
  const [selectedRebate, setSelectedRebate] = useState<Rebate | null>(null);
  const [filter, setFilter] = useState<RebateResultFilter>(defaultFilter);
  const [page, setPage] = useState<number>(1);
  const [paginationMeta, setPaginationMeta] = useState<IPaginationMeta | null>(
    null
  );
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const filteredRebates = rebates.sort((a, b) =>
    a.rebateDate > b.rebateDate ? -1 : 1
  );

  const loadRebates = useCallback(async () => {
    setLoading(true);
    const finalFilter = isPartners
      ? { ...filter, company: activeCompany }
      : filter;
    const res = await RebateClient.getAllPaginated(page, finalFilter);
    const data = res.data as Pagination<Rebate>;
    setRebates(data.items);
    setPaginationMeta(data.meta);
    setSelectedRebate((r) =>
      r ? data.items.find((rebate) => rebate.id === r.id) || r : null
    );
    setLoading(false);
  }, [page, filter, isPartners, activeCompany]);

  useEffect(() => {
    loadRebates();
  }, [filter, isPartners, activeCompany, user, loadRebates]);

  useEffect(() => {
    setPage(p => p !== 1 ? 1 : p);
  }, [filter, isPartners, activeCompany, setPage])

  useEffect(() => {
    loadRebates();
  }, [page, loadRebates]);

  // useeffect to auto open the modal for a targeted rebate on page params
  useEffect(() => {
    if (!id) {
      return;
    }
    console.log(`ID found: ${id}`);
    const getRebate = async (id: string) => {
      const rebate: Rebate | null = await RebateClient.getById(id);
      console.log('single rebate', rebate);
      if (!rebate) {
        return;
      }
      setSelectedRebate(rebate);
    };
    getRebate(id);
  }, [id]);

  const spinAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.timing(spinAnim, {
        toValue: 1,
        duration: 2000,
        easing: Easing.linear,
        useNativeDriver: false,
      }),
      {}
    ).start()
  }, [spinAnim]);

  const renderFilterTags = useMemo(()=> {

    const { company, reviewer, applicant, status } = filter;
    const hasStatus = status !== undefined && status !== null;

    return (
      <>
        {company ? <FilterTag>{company.name}</FilterTag> : null}
        {reviewer ? <FilterTag>{`${reviewer.firstName} ${reviewer.lastName}`}</FilterTag> : null}
        {applicant ? <FilterTag>{`${applicant.firstName} ${applicant.lastName}`}</FilterTag> : null}
        {hasStatus ? <FilterTag>{String(Object.keys(RebateStatus).find(key => RebateStatus[key as keyof typeof RebateStatus] === status))}</FilterTag> : null}
      </>
    )

  }, [filter]);

  return (
    <>
      <DashboardLayout>
        <H1 xl>{!isPartners ? 'Rebates Admin' : 'Rebates'}</H1>
        <P>
          {!isPartners
            ? 'Zentia Admin Portal for managing all rebates.'
            : 'Submit, monitor and manage rebate applications for Pinnacle Partners.'}
        </P>
        {loading ? (
          <>
            <div
              style={tw`w-full h-full flex items-center justify-center align-center`}
            >
              <Animated.View
                style={{
                  transform: [{
                    rotate: spinAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['0deg', '360deg']
                    })
                  }]
                }}
              >
                <CgSpinner
                  fill='#3cdbc0'
                  style={{
                    
                    ...tw`w-22 h-22 text-[#3cdbc0] animate-spin`,
                  }}
                />
              </Animated.View>
            </div>
          </>
        ) : (
          <>
            {/* main content */}
            <div style={tw`my-6 flex justify-between`}>
              <div style={tw`flex flex-row w-full`}>
                <div style={tw`flex flex-col grow`}>
                  <Button
                    variant={'dark'}
                    onPress={() => {
                      setShowFilterModal(true);
                    }}
                  >
                    <FaFilter /> Filters
                  </Button>
                  <div
                    style={tw`flex font-sans mt-2 align-center items-center`}
                  >
                    {Object.entries(filter).filter(
                      (item) => item[0] !== 'quarters' && item[1] !== undefined && item[1] !== null
                    ).length ? (
                      <small style={tw`mr-4`}>Filtering: </small>
                    ) : null}

                    {/* filter tags */}
                    {renderFilterTags}

                    {filter.quarters.length ? (
                      <small
                        key={`filter-dates-display`}
                        style={tw`w-fit p-1 bg-green mr-2`}
                      >
                        {filter.quarters.map((x) => `${x.label} `)}
                      </small>
                    ) : null}
                  </div>
                </div>

                {isPartners ?
                  <>
                  {/* New rebate button */}
                  {getFullQuarters(rebates, 'exclude').length ? (
                    <Button
                      onPress={() => {
                        setShowNewModal(true);
                      }}
                    >
                      + New Rebate
                    </Button>
                  ) : null}
                </> : null
                }
              </div>
            </div>
            {/* List Header - for showing totals items */}
            <div>
              <RebatePaginationControl
                meta={paginationMeta}
                setPage={(p) => setPage(p)}
              />
            </div>
            {/* Results Container for partners */}
            {!isPartners && !filteredRebates.length ? (
              <div style={tw`font-sans`}>
                <p>
                  No Rebate{' '}
                  {!rebates.length ? 'found.' : 'found after filtered.'}
                </p>
              </div>
            ) : (
              <ul style={tw`font-sans w-full flex flex-col m-0 p-0`}>
                {filteredRebates.sort((a, b) => {
                  const aDate = a.rebateDate;
                  const bDate = b.rebateDate;
                  return aDate > bDate ? -1 : 1
                }).map((x) => (
                  <li
                    key={x.id}
                    style={{
                      ...tw`flex flex-row list-none m-0 bg-white p-4 justify-between align-center items-center shrink`,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedRebate(x);
                    }}
                  >
                    <div>
                      <h3>{displayQuarter(x.rebateDate)}{addIndexSuffix(filteredRebates, x)}</h3>
                      <p style={tw`italic text-[#888]`}>{x.company.name}</p>
                    </div>
                    {x.applications.length
                      ? (function () {
                          const sorted = x.applications.sort((a, b) =>
                            a.updatedAt > b.updatedAt ? -1 : 1
                          );
                          const latest = sorted[0];
                          const color =
                            latest.status === 0
                              ? '[#888]'
                              : latest.status === 1
                              ? 'green'
                              : 'red';
                          return (
                            <p style={tw`p-2 text-white bg-${color}`}>
                              {RebateStatus[latest.status]}
                            </p>
                          );
                        })()
                      : null}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}

        {/* modals and alerts */}
        <RebateNewModal
          visible={showNewModal}
          setVisible={(v) => setShowNewModal(v)}
          refresh={() => loadRebates()}
          rebates={rebates}
        />
        <RebateDetailsModal
          rebate={selectedRebate}
          setRebate={() => {
            setSelectedRebate(null);
          }}
          refresh={() => loadRebates()}
        />

        <RebateFilterModal
          visible={showFilterModal}
          setVisible={(v) => setShowFilterModal(v)}
          filter={filter}
          setFilter={setFilter}
          rebates={rebates}
          setPage={setPage}
        />
      </DashboardLayout>
    </>
  );
};

export default RebatesScreen;
