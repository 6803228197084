import { API_BASE_URL } from '../env-consts';
import ApiClient from './ApiClient';

const client = new ApiClient(`${API_BASE_URL}/recal`);
const formDataOptions = {
  headers: {
    'Content-Type': 'multipart/form-data',
    enctype: 'multipart/form-data',
  },
};
export const ReCalClient = {
  async getTemplateFile(
    filename: string
  ){
    return await client.get(`/templateFile?filename=${filename}`);
  },

  async updateTemplate(file: File) {
    const data = new FormData();
    data.set('file', file, file.name);
    const res = await client.post('/template', data, formDataOptions);
    console.log('from recal api', res);
    return res.data;
  },
};
