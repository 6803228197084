import { CompanyResponse } from "./companies";
import { UserResponse } from "./users";

// export type RebateStatus = 'pending' | 'approved' | 'declined' | 'cancelled';

export enum RebateStatus {
  'In Progress' = 0,
  Approved = 1,
  Declined = 2,
  Cancelled = 3
}

export const RebateStatusKeys = Object.keys(RebateStatus).filter(k => Number.isNaN(Number(k))) as (keyof typeof RebateStatus)[];

export interface RebateSupportingEvidence{
  id: string;
  createdAt: Date;
  updatedAt: Date;
  url: string;
  name: string;
}

// template payload
export interface RebateTemplatePayload{
  template: File;
}

// payload for new rebate
export interface RebateNewPayload{
  companyId: string;
  rebateDate: Date | null;
  evidence: File | null;
  supportingEvidences: File[];
  consent: boolean;
}

export interface RebateNewResponse{
  status: number;
  message: string;
}

export interface Rebate {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  company: CompanyResponse;
  rebateDate: Date;
  applications: RebateApplication[]
}

export interface RebateApplication {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  rebateId: string;
  rebate?: Rebate;
  applicant: UserResponse;
  reviewer?: UserResponse;
  evidenceName: string;
  evidenceUrl: string;
  status: RebateStatus;
  comment: string;
  supportingEvidences: RebateSupportingEvidence[];
}

export interface RebateApplicationNewPayload{
  rebateId: string;
  evidence: File | null;
  supportingEvidences: File[];
}

export interface RebateApplicationUpdatePayload{
  id: string;
  status: RebateStatus;
  comment?: string;
}