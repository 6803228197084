import {
  CompositeNavigationProp,
  NavigationAction,
  useLinkProps as useLinkPropsDefault,
} from '@react-navigation/native';
import type {
  CompositeScreenProps,
  NavigatorScreenParams,
} from '@react-navigation/native';
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from '@react-navigation/native-stack';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';

import { WpPost } from '../shared/types/wordpress';

// root stack
export type RootStackParamList = {
  resetPassword: {
    token: string;
    userId: string;
  };
  acceptInviteUser: {
    token: string;
    userId: string;
    companyId: undefined;
  };
  acceptInviteCompany: {
    token: string;
    userId: string;
    companyId: string;
  };
  authenticatedStackNavigator: NavigatorScreenParams<AuthenticatedStackNavigatorParamList>;
  unauthenticatedStackNavigator: NavigatorScreenParams<UnauthenticatedStackNavigatorParamList>;
};

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, T>;

// authenticated Stack
export type AuthenticatedStackNavigatorParamList = {
  home: undefined;
  projectsOverview: undefined;
  projectStack: NavigatorScreenParams<ProjectStackNavigatorParamList>;
  newsStack: NavigatorScreenParams<NewsStackNavigatorParamList>;
  globalAdminStack: NavigatorScreenParams<GlobalAdminStackNavigatorParamList>;
  companyAdminStack: NavigatorScreenParams<CompanyAdminStackNavigatorParamList>;
  distributors: undefined;
  resources: undefined;
  recyclingCal: undefined;
  training: undefined;
  contacts: undefined;
  myAccount: undefined;
  'myAccount:invoices': undefined;
  rebates: undefined;
  favourites: undefined;
  invoices: undefined;
};

export type AuthenticatedStackNavigatorProps<
  T extends keyof AuthenticatedStackNavigatorParamList
> = CompositeScreenProps<
  NativeStackScreenProps<AuthenticatedStackNavigatorParamList, T>,
  RootStackScreenProps<keyof RootStackParamList>
>;

// projects stack
export type ProjectStackNavigatorParamList = {
  createProject: undefined;
  project: {
    id: string;
  };
  task: {
    projectId: string;
    taskId: string;
  };
  snag: {
    projectId: string;
    snagId: string;
  };
  room: {
    projectId: string;
    roomId: string;
  };
  document: {
    projectId: string;
    documentId: string;
  };
};

export type ProjectStackNavigatorProps<
  T extends keyof ProjectStackNavigatorParamList
> = CompositeScreenProps<
  NativeStackScreenProps<ProjectStackNavigatorParamList, T>,
  AuthenticatedStackNavigatorProps<'projectStack'>
>;

// news stack
export type NewsStackNavigatorParamList = {
  news: undefined;
  newsArticle: {
    slug: string;
    post?: WpPost;
  };
};

// admin stack
export type GlobalAdminStackNavigatorParamList = {
  admin: undefined;
  user: {
    id: string;
  };
  company: {
    id: string;
  };
  leads: {
    id: string;
  };
  contacts: undefined;
};

export type GlobalAdminStackNavigationProp<
  T extends keyof GlobalAdminStackNavigatorParamList
> = CompositeNavigationProp<
  NativeStackNavigationProp<GlobalAdminStackNavigatorParamList, T>,
  NativeStackNavigationProp<
    AuthenticatedStackNavigatorParamList,
    'globalAdminStack'
  >
>;

// company admin stack
export type CompanyAdminStackNavigatorParamList = {
  company: undefined;
};

// unauthenticated stack
export type UnauthenticatedStackNavigatorParamList = {
  login?: {
    test: string;
  };
  forgotPassword: undefined;
  acceptInviteUser: {
    token: string;
  };
  acceptInviteCompany: {
    token: string;
  };
};

export type UnauthenticatedStackNavigatorProps<
  T extends keyof UnauthenticatedStackNavigatorParamList
> = CompositeScreenProps<
  NativeStackScreenProps<UnauthenticatedStackNavigatorParamList, T>,
  RootStackScreenProps<keyof RootStackParamList>
>;

// global

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export default function useLinkProps<
  RouteName extends keyof RootStackParamList = keyof RootStackParamList
>(to: To<RootStackParamList, RouteName>, action?: NavigationAction) {
  return useLinkPropsDefault({ to, action });
}
