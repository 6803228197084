import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import * as Yup from 'yup';
import { GlobalSettingsClient } from '../../api/GlobalSettingsClient';
import tw from '../../config/tailwind';
import { useToasts } from '../../hooks/useToasts';
import {
  GlobalSettingsIds,
  GlobalSettingsResponse,
  UpdateGlobalSettingsPayload,
} from '../../shared/types/globalSettings';
import { resolveTemplateFile } from '../../views/AuthenticatedStack/Rebates/RebateNewModal';
import Button from '../shared/Button';
import InputText from '../shared/InputText';
import InputValidationMessage from '../shared/InputValidationMessage';
import DownloadSVG from '../svg/DownloadSVG';
import ReCalTemplateUploadModal from './modals/ReCalTemplateUploadModal';
import RebateTemplateUploadModal from './modals/RebateTemplateUploadModal';

export type T_FileTemplate = {
  url: string;
  name: string;
}
export interface RebateTemplate extends T_FileTemplate {};

interface Props {}

const AdminGlobalSettingsPage: React.FC<Props> = ({}) => {
  const { addToast, addUnhandledErrorToast } = useToasts();
  const [settings, setSettings] = useState<GlobalSettingsResponse[]>([]);
  const [mailingList, setMailingList] = useState<string[]>([]);
  const [rebateTemplate, setRebateTemplate] = useState<
    RebateTemplate | undefined
  >(undefined);
  const [rebateMailingList, setRebateMailingList] = useState<string[]>([]);
  const [showUploadTemplateModal, setShowUploadTemplateModal] = useState(false);
  
  const [reCalTemplate, setReCalTemplate] = useState<
    T_FileTemplate | undefined
  >(undefined);
  const [showUploadReCal, setShowUploadReCal] = useState(false);

  const initValue: { email: string } = { email: '' };

  const emailSchema: Yup.SchemaOf<{ email: string }> = Yup.object().shape({
    email: Yup.string()
      .email('Please provide a valid email address')
      .required(),
  });

  const loadSettings = async () => {
    const res = await GlobalSettingsClient.getAll();
    // console.log(res)
    setSettings(res);
    const tempMailingList = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.mailingList)?.value || '[]'
    );
    const tempRebateTemplate = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.rebateTemplate)?.value || '{}'
    ) as RebateTemplate;

    const tempRebateMailingList = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.rebateMailingList)?.value ||
        '[]'
    );

    const tempReCalTemplate = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.recalTemplate)?.value || '{}'
    ) as RebateTemplate;

    setMailingList(tempMailingList);
    setRebateTemplate(tempRebateTemplate);
    setRebateMailingList(tempRebateMailingList);
    setReCalTemplate(tempReCalTemplate);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const updateMailingList = async (
    values: { email: string },
    callback: () => void
  ) => {
    // console.log(values);
    if (mailingList.includes(values.email)) {
      addToast({
        title: 'Email Already in list',
        description: `The email you are trying to add is already subscribed previously.`,
        type: 'error',
      });
      return;
    }

    try {
      const tempMailingList = mailingList.concat(values.email);

      const mailingListSettings = settings.find(
        (x) => x.id === GlobalSettingsIds.mailingList
      );
      if (!mailingListSettings) {
        addUnhandledErrorToast('');
        return;
      }
      const { id, ...data } = mailingListSettings;
      const payload: UpdateGlobalSettingsPayload = Object.assign(
        { ...data },
        {
          value: JSON.stringify(tempMailingList),
        }
      );

      await GlobalSettingsClient.update(id, payload);
      addToast({
        title: 'Success!',
        description: `${values.email} Subscribed to notification!`,
        type: 'success',
      });

      loadSettings();
      callback();
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  const handleDelete = async (email: string) => {
    try {
      const tempMailingList = mailingList.filter((x) => x !== email);
      const mailingListSettings = settings.find(
        (x) => x.id === GlobalSettingsIds.mailingList
      );
      if (!mailingListSettings) {
        addUnhandledErrorToast('');
        return;
      }
      const { id, ...data } = mailingListSettings;
      const payload: UpdateGlobalSettingsPayload = Object.assign(
        { ...data },
        {
          value: JSON.stringify(tempMailingList),
        }
      );

      await GlobalSettingsClient.update(id, payload);
      addToast({
        title: 'Email Deleted!',
        description: `${email} is now unubscribed to notification!`,
        type: 'success',
      });

      loadSettings();
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  const updateRebateMailingList = async (
    values: { email: string },
    callback: () => void
  ) => {
    // console.log(values);
    if (rebateMailingList.includes(values.email)) {
      addToast({
        title: 'Email Already in list',
        description: `The email you are trying to add is already subscribed previously.`,
        type: 'error',
      });
      return;
    }

    try {
      const tempMailingList = rebateMailingList.concat(values.email);

      const mailingListSettings = settings.find(
        (x) => x.id === GlobalSettingsIds.rebateMailingList
      );
      if (!mailingListSettings) {
        addUnhandledErrorToast('');
        return;
      }
      const { id, ...data } = mailingListSettings;
      const payload: UpdateGlobalSettingsPayload = Object.assign(
        { ...data },
        {
          value: JSON.stringify(tempMailingList),
        }
      );

      await GlobalSettingsClient.update(id, payload);
      addToast({
        title: 'Success!',
        description: `${values.email} Subscribed to rebate notification!`,
        type: 'success',
      });

      loadSettings();
      callback();
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  const handleDeleteRebateEmail = async (email: string) => {
    try {
      const tempMailingList = rebateMailingList.filter((x) => x !== email);
      const mailingListSettings = settings.find(
        (x) => x.id === GlobalSettingsIds.rebateMailingList
      );
      if (!mailingListSettings) {
        addUnhandledErrorToast('');
        return;
      }
      const { id, ...data } = mailingListSettings;
      const payload: UpdateGlobalSettingsPayload = Object.assign(
        { ...data },
        {
          value: JSON.stringify(tempMailingList),
        }
      );

      await GlobalSettingsClient.update(id, payload);
      addToast({
        title: 'Email Deleted!',
        description: `${email} is now unubscribed to rebate notification!`,
        type: 'success',
      });

      loadSettings();
    } catch (error) {
      addUnhandledErrorToast(error);
    }
  };

  return (
    <View>
      {/* Mailing list section */}
      <View>
        <View>
          <View style={tw.style(`font-sans`)}>
            <h2>Notification Mailing List</h2>
            <p style={tw.style(`text-gray-400 p-0 m-0`)}>
              List of email to send Global email notification to
            </p>
          </View>
          <View style={tw.style(`flex my-4 flex-col`)}>
            {!mailingList.length ? (
              <View
                style={tw.style(
                  `flex flex-row justify-between border-b-[1px] border-slate-100 py-4`
                )}
              >
                <p style={tw.style(`font-sans`)}>No email subscribed.</p>
              </View>
            ) : (
              mailingList.map((y, i) => (
                <View
                  key={`notification-email-${y}-${i}`}
                  style={tw.style(
                    `flex flex-row justify-between border-b-[1px] border-slate-100 py-4`
                  )}
                >
                  <p style={tw.style(`font-sans`)}>{y}</p>
                  <Button
                    style={tw.style(`p-2`)}
                    onPress={() => {
                      handleDelete(y);
                    }}
                  >
                    Delete
                  </Button>
                </View>
              ))
            )}
          </View>
        </View>
        <View style={tw.style('mt-8')}>
          <Formik
            initialValues={initValue}
            onSubmit={(v, { resetForm }) => {
              updateMailingList(v, resetForm);
            }}
            validationSchema={emailSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values,
              errors,
              isSubmitting,
            }) => (
              <View>
                <InputText
                  placeholder='john@example.co.uk'
                  label='Subscribe a new email'
                  required
                  value={values.email}
                  setValue={handleChange('email')}
                  hasError={!!errors.email}
                />
                {errors.email && (
                  <InputValidationMessage>
                    {errors.email}
                  </InputValidationMessage>
                )}
                {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
                <Button onPress={handleSubmit} disabled={isSubmitting}>
                  + Add Email
                </Button>
              </View>
            )}
          </Formik>
        </View>
      </View>

      {/* Recycling Calculator Management */}
      <View style={tw`mt-12`}>
        <View>
          <View style={tw.style(`font-sans`)}>
            <h2>Recycling Calculator</h2>
            <p style={tw.style(`text-gray-400 p-0 m-0`)}>
              Manage the recycling calculator Excel file (.xls)
            </p>
          </View>

          <View
            style={tw`w-full flex flex-row justify-center my-4 py-4 border-b-[1px] border-slate-100`}
          >
            <a
              style={tw`font-sans mr-auto bg-[#eee] p-4 flex flex-rows justify-center align-center items-center text-dark no-underline`}
              href={'#'}
              onClick={() =>
                reCalTemplate ? resolveTemplateFile(reCalTemplate.name) : {}
              }
            >
              <DownloadSVG style={tw`mr-2`} />
              {reCalTemplate?.name ?? 'No Template Uploaded'}
            </a>
            <Button
              style={tw.style(`p-2 self-center`)}
              onPress={() => {
                setShowUploadReCal(true);
              }}
            >Upload</Button>
          </View>
        </View>
      </View>

      {/* Rebate Section */}
      <View style={tw`mt-12`}>
        <View>
          <View style={tw.style(`font-sans`)}>
            <h2>Rebate Settings</h2>
          </View>
          <View style={tw.style(`font-sans`)}>
            <h3>Rebate Template</h3>
            <p style={tw.style(`text-gray-400 p-0 m-0`)}>
              Template Excel file (.xls) for partners to use when applying for
              rebate.
            </p>
          </View>
          <View
            style={tw`w-full flex flex-row justify-center my-4 py-4 border-b-[1px] border-slate-100`}
          >
            <a
              style={tw`font-sans mr-auto bg-[#eee] p-4 flex flex-rows justify-center align-center items-center text-dark no-underline`}
              href={'#'}
              onClick={() =>
                rebateTemplate ? resolveTemplateFile(rebateTemplate.name) : {}
              }
            >
              <DownloadSVG style={tw`mr-2`} />
              {rebateTemplate?.name ?? 'No Template Uploaded'}
            </a>
            <Button
              style={tw.style(`p-2 self-center`)}
              onPress={() => {
                setShowUploadTemplateModal(true);
              }}
            >
              Upload New Template
            </Button>
          </View>

          {/* Rebate Mailing List */}
          <View>
            <View>
              <View style={tw.style(`font-sans mt-4`)}>
                <h3>Rebate Mailing List</h3>
                <p style={tw.style(`text-gray-400 p-0 m-0`)}>
                  List of email to send new Rebate email notification to
                </p>
              </View>
              <View style={tw.style(`flex my-4 flex-col`)}>
                {!rebateMailingList.length ? (
                  <View
                    style={tw.style(
                      `flex flex-row justify-between border-b-[1px] border-slate-100 py-4`
                    )}
                  >
                    <p style={tw.style(`font-sans`)}>No email subscribed.</p>
                  </View>
                ) : (
                  rebateMailingList.map((y, i) => (
                    <View
                      key={`notification-email-${y}-${i}`}
                      style={tw.style(
                        `flex flex-row justify-between border-b-[1px] border-slate-100 py-4`
                      )}
                    >
                      <p style={tw.style(`font-sans`)}>{y}</p>
                      <Button
                        style={tw.style(`p-2`)}
                        onPress={() => {
                          handleDeleteRebateEmail(y);
                        }}
                      >
                        Delete
                      </Button>
                    </View>
                  ))
                )}
              </View>
            </View>
            <View style={tw.style('mt-8')}>
              <Formik
                initialValues={initValue}
                onSubmit={(v, { resetForm }) => {
                  updateRebateMailingList(v, resetForm);
                }}
                validationSchema={emailSchema}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  isSubmitting,
                }) => (
                  <View>
                    <InputText
                      placeholder='john@example.co.uk'
                      label='Subscribe a new email'
                      required
                      value={values.email}
                      setValue={handleChange('email')}
                      hasError={!!errors.email}
                    />
                    {errors.email && (
                      <InputValidationMessage>
                        {errors.email}
                      </InputValidationMessage>
                    )}
                    {isSubmitting && <ActivityIndicator style={tw`mr-4`} />}
                    <Button onPress={handleSubmit} disabled={isSubmitting}>
                      + Add Email
                    </Button>
                  </View>
                )}
              </Formik>
            </View>
          </View>

          {/* upload template modal */}
          <RebateTemplateUploadModal
            visible={showUploadTemplateModal}
            setVisible={setShowUploadTemplateModal}
            callback={loadSettings}
          />

          {/* upload modal for recycling calculator */}
          <ReCalTemplateUploadModal 
            visible={showUploadReCal}
            setVisible={setShowUploadReCal}
            callback={loadSettings}
          />
        </View>
      </View>

      {/* padding view */}
      <View style={tw`pb-24`}></View>
    </View>
  );
};

export default AdminGlobalSettingsPage;
