import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { GlobalSettingsClient } from '../../api/GlobalSettingsClient';
import { ReCalClient } from '../../api/ReCalClient';
import { T_FileTemplate } from '../../components/admin/AdminGlobalSettingsPage';
import H1 from '../../components/shared/H1';
import P from '../../components/shared/P';
import DownloadSVG from '../../components/svg/DownloadSVG';
import tw from '../../config/tailwind';
import DashboardLayout from '../../layout/DashboardLayout';
import { GlobalSettingsIds } from '../../shared/types/globalSettings';
import Button from '../../components/shared/Button';

export const resolveTemplateFile = async (filename: string) => {
  const freshUrl = await ReCalClient.getTemplateFile(filename);
  console.log('freshUrl', freshUrl);
  const link = document.createElement('a');
  link.href = freshUrl.data;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function RecyclingCalScreen() {
  const [template, setTemplate] = useState<T_FileTemplate | undefined>(
    undefined
  );

  const loadSettings = async () => {
    const res = await GlobalSettingsClient.getAll();
    const tempTemplate = JSON.parse(
      res.find((x) => x.id === GlobalSettingsIds.recalTemplate)?.value || '{}'
    ) as T_FileTemplate;
    setTemplate(tempTemplate);
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <DashboardLayout>
      <H1 xl>Recycling Calculator</H1>

      <View style={tw`bg-white p-8`}>
        <P>
          Download the recycling calculator here to estimate recycling cost.
        </P>

        {template?.name ? (
          <Button onPress={() => resolveTemplateFile(template.name)}>
            <div style={tw`flex items-center justify-center`}>
              <DownloadSVG style={tw`mr-2`} />
              <span>Download Recycling Calculator</span>
            </div>
          </Button>
        ) : null}
      </View>
    </DashboardLayout>
  );
}
